import React, {useState} from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { FilterOutline } from 'react-ionicons'
import { MapOutline } from 'react-ionicons'

import Navbar from '../components/Nav';
import Footer from '../components/Footer';
import Hero from '../components/Hero';

import Property from '../components/Property';
import Mymap from '../modules/map';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Popupmodal from '../components/Popupmodal';

import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { useMediaQuery } from 'react-responsive';

const mapClassShow = 'w-full h-96 md:h-auto min-h-full bg-green-400'
const mapClassHide = 'hidden'

const contentStyle = { width: '90%', height: '90%', overflow: 'scroll'};

export default function Portfolio({location}) {
    const [map, setmap] = useState(false);
    const [dropdown, setdropdown] = useState(false);
    const [activeFilter, setActiveFilter] = useState('');
    const [filterResults, setFilterResults] = useState([]);
    const [popup , setPopup] = useState(false);
    const [activeProperty, setProperty] = useState({name: 'TBD'});

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const data = useStaticQuery(
        graphql`
        query {
            allPropertiesJson(sort: {fields: status, order: ASC}) {
              edges {
                node {
                  name
                  status
                  type
                  summary
                  address {
                    city
                    state
                    lat
                    lng
                  }
                  contact {
                    website
                  }
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 100, formats: WEBP )
                    }
                  }
                }
              }
            }

            file(relativePath: {eq: "Properties/Sierra Vista/sierra-vista.JPG"}) {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
        `)

  
    const heroImage = data.file.childImageSharp.gatsbyImageData;
    const propertyEdges = data.allPropertiesJson.edges;

    React.useEffect(() => {
        let results = [];
        if (location.state === null) {
          setActiveFilter('All');
        }

        if (activeFilter === 'All') {
            results = propertyEdges;
        } else {
            results = propertyEdges.filter((property) => property.node.status === activeFilter);
        }
        setFilterResults(results);
    }, [activeFilter])

    const handlePropertyClick = name => {
      setPopup(!popup)
      setProperty(name)
    }

    const handleMarkerClick = property => {
      setPopup(!popup)
      setProperty(property)
    }

    React.useEffect(() => {
      if (location.state && location.state.filter === 'senior' && activeFilter === '') {
        let results = [];
        results = propertyEdges.filter((property) => property.node.name === "Fiji Senior Villas");
        results = [...results, ... propertyEdges.filter((property) => property.node.name === "Sierra Vista Senior Villas")]
        setFilterResults(results);
      } else {
        setActiveFilter('All')
      }
    }, [activeFilter])
  
    
    return (
      <div>
        <Navbar isActive="Portfolio"/>
        <Hero title="Our Properties" image={heroImage}/>

        {/* Portfolio Introduction */}
        <section className="py-14 px-6">
          <div className="container mx-auto flex flex-wrap items-center lg:items-start justify-center max-w-7xl md:space-x-4 space-y-12 lg:space-y-0 lg:flex-nowrap">
            <div className="w-full lg:flex-1">
              <h1 className="text-4xl font-light text-sphinx-accent font-montserrat md:text-left md:text-3xl pb-2">
                Your New Home Awaits.
              </h1>
              <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose font-light">
              From the metropolitan highlights of Dallas to the countryside outskirts of Fort Worth, you will find every Sphinx community throughout the DFW Metroplex to be as handsome and reasonably priced as the last. 
              </p>
            </div>
            <ParallaxProvider>
              <div className="grid relative grid-cols-6 h-auto place-items-end">
              
                <div className="-mb-5 col-start-2 row-start-1 col-span-5 flex justify-between py-2 shadow-xl bg-gray-100 w-full h-20 z-10 items-center">
                 
                    <div className="flex-1 text-center">
                      <h4 className="text-xl font-normal font-montserrat text-sphinx-accent md:text-3xl">
                        5%
                      </h4>
                      <h5 className="text-sm font-montserrat md:text-base">
                        Vacancy Rate
                      </h5>
                    </div>
                    <div className="flex-1 text-center">
                      <h4 className="text-xl font-normal font-montserrat text-sphinx-accent md:text-3xl">
                        30+
                      </h4>
                      <h5 className="text-sm font-montserrat md:text-base">
                        Accreditations
                      </h5>
                    </div>
                    <div className="flex-1 text-center">
                    <h4 className="text-xl font-normal font-montserrat text-sphinx-accent md:text-3xl">
                      3500+
                    </h4>
                    <h5 className="text-sm font-montserrat md:text-base">
                      Residents
                    </h5>
                  </div>
                
                </div>
                
                <div className="col-start-1 row-start-1 col-span-5">
                  <Parallax y={isMobile ? [0,0] : ["60px", "-70px"]} disabled={isMobile}>
                    <StaticImage
                      src="../images/Properties/Harmon Villas/Harmon-Villas_1.jpg"
                      alt="Property Image"
                      width={450}/>
                    </Parallax>
                </div>
                
              </div>
              </ParallaxProvider>
            </div>
        </section>
        


        {/* Filter Section for Mobile*/}
        <div className="w-full bg-sphinx-background-filter h-auto sticky top-20 z-10">
          <div className="container mx-auto flex flex-wrap py-4 px-6 max-w-7xl">
            <div className="flex flex-1 lg:hidden space-x-4 items-center">
                <FilterOutline color={"#00000"} height="33px" width="33px" />
   
                <div className="relative inline-block text-left">
                    {/* Button to control selection menu */}
                    <div>
                        <button type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-montserrat font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="options-menu" aria-expanded="true" aria-haspopup="true" onClick={() => setdropdown(!dropdown)}>
                        {activeFilter}
                        <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        </button>
                    </div>

                    <div className={(dropdown ? "inline-block" : "hidden") + " origin-top-left absolute left-0 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"} role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <div className="py-1" role="none">
                            <button onClick={() => {setActiveFilter('All'); setdropdown(false)}} className="block w-full text-left px-4 py-2 text-sm text-gray-700 font-sanspro hover:bg-gray-100 hover:text-gray-900" role="menuitem"> All </button>
                            <button onClick={() => {setActiveFilter('Managed'); setdropdown(false)}} className="block w-full text-left px-4 py-2 text-sm text-gray-700 font-sanspro hover:bg-gray-100 hover:text-gray-900" role="menuitem"> Managed </button>
                            <button onClick={() => {setActiveFilter('Sold'); setdropdown(false)}} className="block w-full text-left px-4 py-2 text-sm text-gray-700 font-sanspro hover:bg-gray-100 hover:text-gray-900" role="menuitem"> Sold </button>
                            <button onClick={() => {setActiveFilter('Under Construction'); setdropdown(false)}} className="block w-full text-left px-4 py-2 text-sm text-gray-700 font-sanspro hover:bg-gray-100 hover:text-gray-900" role="menuitem"> Under Construction </button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="hidden lg:flex flex-1 space-x-4">
              <button onClick={() => setActiveFilter('All')} className={(activeFilter === 'All' ? "bg-sphinx-background-dark text-sphinx-white" : "bg-sphinx-background-button") + " w-36 min-w-min h-9 text-center shadow rounded-full font-montserrat font-light text-base hover:bg-sphinx-background-dark hover:text-sphinx-white"}>All</button>
              <button onClick={() => setActiveFilter('Managed')} className={(activeFilter === 'Managed' ? "bg-sphinx-background-dark text-sphinx-white" : "bg-sphinx-background-button") + " w-36 min-w-min h-9 text-center  shadow rounded-full font-montserrat font-light text-base hover:bg-sphinx-background-dark hover:text-sphinx-white"}>Managed</button>
              <button onClick={() => setActiveFilter('Under Construction')} className={(activeFilter === 'Under Construction' ? "bg-sphinx-background-dark text-sphinx-white" : "bg-sphinx-background-button") + " w-48 min-w-min h-9 text-center  shadow rounded-full font-montserrat font-light text-base hover:bg-sphinx-background-dark hover:text-sphinx-white"}>Under Construction</button>
              <button onClick={() => setActiveFilter('Sold')} className={(activeFilter === 'Sold' ? "bg-sphinx-background-dark text-sphinx-white" : "bg-sphinx-background-button") + " w-36 min-w-min h-9 text-center  shadow rounded-full font-montserrat font-light text-base hover:bg-sphinx-background-dark hover:text-sphinx-white"}>Sold</button>
            </div>

            <div className="flex items-center space-x-3 justify-center">
              <h6 className="hidden md:inline-block font-montserrat text-base font-medium">
                Map
              </h6>
              <MapOutline color={"#00000"} height="20px" width="20px" />
              <div className="flex justify-between items-center">
                <button
                  className="w-14 h-6 flex items-center bg-gray-300 rounded-full p-1"
                  onClick={() => setmap(!map)}
                >
                  <div
                    className={
                      "bg-white w-5 h-5 rounded-full shadow-md transform duration-300 ease-in-out " +
                      (map ? "translate-x-6" : "translate-x-0")
                    }
                  ></div>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Properties Grid */}
        <section className="py-6">
          <div className="w-full">
            <div className="container mx-auto flex flex-wrap-reverse justify-center px-4 md:flex-nowrap md:space-x-6">
              <div className="flex flex-shrink-0 pt-6 md:pt-0">
                <div
                  className={
                    "grid gap-4 grid-cols-1 sm:grid-cols-2 " +
                    (map
                      ? "md:grid-cols-1 lg:grid-cols-2"
                      : "md:grid-cols-2 lg:grid-cols-3 ")
                  }
                >
                  {filterResults.map(node => (
                    <Property info={node.node} handleClick={() => handlePropertyClick(node.node)}/>
                  ))}
                </div>
              </div>
              {/* Map Component */}
              <div className={map ? mapClassShow : mapClassHide}>
                <Mymap properties={ propertyEdges } handleClick={(edge) => handleMarkerClick(edge)}/>
              </div>
            </div>
          </div>
        </section>

        {/* Popup Window Service */}
      
        <Popup 
          open={popup}
          {...{contentStyle}}
          closeOnDocumentClick={false}
          lockScroll={true}
          modal>
            <Popupmodal handleClosePopup={() => setPopup(!popup)} name={"Developer Card"} property={activeProperty}/>
        </Popup>
        <Footer />
      </div>
    )
}
