import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Home } from 'react-ionicons'
import { Globe } from 'react-ionicons'

import Mymap from '../modules/map';

export default function Popupmodal(props) {
    const image = getImage(props.property.image)
    const handleMarkerClick = () => { return; }
    const formattedObjectForMap = [{node:props.property}]
    return (
        <div className="modal px-3 container mx-auto lg:max-w-7xl lg:px-6">
            <button className="float-right text-2xl" onClick={props.handleClosePopup}>X</button>
            <div className="mt-3 grid h-72 w-full">
                <GatsbyImage
                    style={{
                    gridArea: "1/1",
                    }}
                    layout="fullWidth"
                    image={image}
                    alt={props.property.name + " image"}
                />
            </div>
            <div className="px-3 flex space-x-0 py-9 flex-wrap lg:flex-nowrap space-y-5 lg:space-y-0">
                <div className="content container mx-auto font-montserrat text-base space-y-3 max-w-prose">
                    <h1 className="text-3xl font-light font-montserrat text-sphinx-accent"> {props.property.name} </h1>
                    <h6 className="text-xl font-light font-sanspro text-gray-700">{props.property.address.city}, {props.property.address.state}</h6>
                    <div className="space-y-4 md:space-y-0 md:flex md:space-x-5">
                    {props.property.type && <div className="flex space-x-3 items-center"><Home color={'#347c90'} title={"Home Status"} height="25px" width="25px"/> <h2 className="text-base font-sanspro text-gray-800">{props.property.type}</h2></div>}
                    {props.property.contact.website && <div className="flex space-x-3 items-center"><Globe color={'#347c90'} title={"Home Status"} height="25px" width="25px"/> <a href={props.property.contact.website} className="text-base font-sanspro text-gray-800">Property Website</a></div>}
                    </div>
                    <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose font-light">{props.property.summary}</p>
                </div>

                <div className="lg:flex-1 h-96 bg-red-400 w-full">
                    <Mymap properties={formattedObjectForMap} handleClick={() => handleMarkerClick()}/>
                </div>
            </div>
      </div>
    )
}
