import React from 'react'
import { getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"

export default function Property(props) {
    const image = getImage(props.info.image)
    const bgImage = convertToBgImage(image)
    let status = props.info.status
    let className = 'Owned';
    switch(status) {
        case 'Sold':
            className = 'sold'
            break;
        case 'Managed':
            className = 'managed'
            break;
        case 'Under Construction':
            className = 'under-construction'
            break;
        default:
            className = 'owned'
            status = 'Owned'
            break;
    }

    return (
        <button onClick={props.handleClick}>
            <BackgroundImage Tag="section" className="flex justify-center items-center h-64" {...bgImage} backgroundColor={"#272D2D"}preserveStackingContext>
                <div className="flex flex-wrap justify-center items-center w-80 h-full bg-sphinx-light-blue bg-opacity-40 relative">
                    <div className="absolute left-2 top-2 flex items-center space-x-2 justify-center">
                        <h6 className={className}>{status}</h6>
                    </div>
                    <div className="flex flex-wrap justify-center">
                        <h5 className="w-full text-center text-2xl text-sphinx-white font-montserrat font-semibold">{props.info.name}</h5>
                        <h6 className="text-center text-lg text-sphinx-white font-montserrat font-medium">{props.info.type} / {props.info.address.city}, {props.info.address.state}</h6>
                    </div>
                </div>
            </BackgroundImage>
        </button>
    )
}
